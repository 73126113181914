<template>
<div>
<nav-bar></nav-bar>
  <div class="container-fluid page-body-wrapper">
  <side-bar></side-bar>  
  <div class="main-panel">
    <div class="content-wrapper">      
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">User Details</h4>
              <form class="form-sample">
                <div class="row">
                  <input type="file" style="display: none" accept="image/*" ref="image" v-on:change="handleFileInput"/>
                  <div class="col-md-3" @click="openImagePicker()">
                    <div class="imagec">
                          <img src="../assets/dummy-profile.jpg" loading="lazy" v-if="profile_pic == ''" class="img-fluid w-100" >
                          <img v-else :src="profilePic()" alt="profile-pic" class="img-fluid w-100" @error="handleImageError" loading="lazy">
                          <div class="img-top-right" v-if="profile_pic">
                            <button type="button" @click="deleteProfilePic()" class="btn btn-outline-danger btn-rounded btn-icon"><i class="mdi mdi-delete"></i></button>
                          </div>
                          <div class="img-centered" v-if="img_progress">
                            <b-spinner label=""></b-spinner>
                          </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group row">                      
                      <div class="col-sm-12">
                        <label class="col-form-label">User/Admin/Doctor/Compounder Name</label>
                        <input type="text" class="form-control" v-model="$v.typeform.doctor_name.$model" :class="{ 'is-invalid':  $v.typeform.doctor_name.$error  }" name="doctor_name" placeholder="Doctor Name"/>
                        <div v-if="$v.typeform.doctor_name.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.doctor_name.required">Please enter user name</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Email Address</label>
                        <input type="text" v-if="$route.params.id" class="form-control" v-model="$v.typeform.doctor_email.$model" name="doctor_email_address" placeholder="Email Address" :class="{ 'is-invalid':  $v.typeform.doctor_email.$error  }" disabled/>
                        <input type="text" v-else class="form-control" v-model="$v.typeform.doctor_email.$model" name="doctor_email_address" placeholder="Email Address" :class="{ 'is-invalid':  $v.typeform.doctor_email.$error  }"/>
                        <div v-if="$v.typeform.doctor_email.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.doctor_email.required">Please enter email address</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Mobile Number</label>
                        <input type="text" class="form-control" v-model="$v.typeform.doctor_mobile_number.$model" name="doctor_mobile_number" placeholder="Doctor Mobile" :class="{ 'is-invalid':  $v.typeform.doctor_mobile_number.$error  }"/>
                        <div v-if="$v.typeform.doctor_mobile_number.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.doctor_mobile_number.required">Please enter mobile number</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Emergency Mobile Number</label>
                        <input type="text" class="form-control"
                            v-model="$v.typeform.doctor_emergency_contact_number.$model"
                            name="doctor_emergency_mobile_number"
                            placeholder="Emergency Mobile Number"
                            :class="{ 'is-invalid':  $v.typeform.doctor_emergency_contact_number.$error  }"/>
                        <div v-if="$v.typeform.doctor_emergency_contact_number.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.doctor_emergency_contact_number.required">Please enter emergency mobile number</span>
                        </div>
                      </div>
                    </div>
                  </div>
                    <div class="col-md-4">
                      <label class="col-form-label">Department</label>
                      <div class="col-sm-12">
                        <div class="col-sm-10">
                          <multiselect v-model="department_selected" :options="departments"
                                        :multiple="true" :close-on-select="false" :clear-on-select="false"
                                        :preserve-search="true" placeholder="Department" label="department_name"
                                        track-by="department_name" :preselect-first="true" :showLabels="false"></multiselect>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">ID Card Number</label>
                        <input type="text" class="form-control" v-model="$v.typeform.identification.$model" name="identification" placeholder="Identification" :class="{ 'is-invalid':  $v.typeform.identification.$error  }"/>
                        <div v-if="$v.typeform.identification.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.identification.required">Please enter id card number</span>
                        </div>
                      </div>
                    </div>
                 </div>
                  <div class="col-md-3">
                      <div class="form-group row">
                        <div class="col-sm-12">
                          <label class="col-form-label">Gender</label>
                          <multiselect v-model="$v.typeform.gender.$model" :options="gender_list"
                                       :multiple="false" :close-on-select="true" :clear-on-select="false"
                                       :preserve-search="true" placeholder="Select Gender"
                                       :preselect-first="true" :showLabels="false"></multiselect>
                          <div v-if="$v.typeform.gender.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform.gender.required">Please select gender</span>
                          </div>
                        </div>
                      </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Married?</label>
                        <multiselect v-model="$v.typeform.marital_status.$model" :options="marital_status_list"
                                   :multiple="false" :close-on-select="true" :clear-on-select="false"
                                   :preserve-search="true" placeholder="Select Marital Status"
                                   :preselect-first="true" :showLabels="false"></multiselect>
                        <div v-if="$v.typeform.marital_status.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.marital_status.required">Please select marital status</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Birth Date</label>
                        <div class="form-control">
                            <DatePicker class="date-picker"
                                        v-model="$v.typeform.birth_date.$model"
                                        format="DD-MM-YYYY"
                                        placeholder="Date of Birth"
                                        :disabled-date="disableDatesAfterMax"/>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Blood Group</label>
                        <multiselect v-model="$v.typeform.blood_group.$model" :options="blood_group_list"
                                       :multiple="false" :close-on-select="true" :clear-on-select="false"
                                       :preserve-search="true" placeholder="Select Blood Group"
                                       :preselect-first="true" :showLabels="false"></multiselect>
                        <div v-if="$v.typeform.marital_status.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.marital_status.required">Please select marital status</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Address</label>
                        <textarea rows="5" type="text"
                                    class="form-control"
                                    v-model="$v.typeform.doctor_address.$model"
                                    name="address" placeholder="Address"
                                    :class="{ 'is-invalid':  $v.typeform.doctor_address.$error }"/>
                        <div v-if="$v.typeform.doctor_address.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.doctor_address.required">Please enter address</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">City</label>
                        <input type="text" class="form-control"
                                v-model="$v.typeform.doctor_city.$model"
                                name="doctor_city" placeholder="City"
                                :class="{ 'is-invalid':  $v.typeform.doctor_city.$error  }"/>
                        <div v-if="$v.typeform.doctor_city.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.doctor_city.required">Please enter city</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Postal Code</label>
                        <input type="number" class="form-control"
                                v-model="$v.typeform.doctor_postal_code.$model"
                                name="doctor_postal_code" placeholder="Postal Code"
                                :class="{ 'is-invalid':  $v.typeform.doctor_postal_code.$error  }"/>
                        <div v-if="$v.typeform.doctor_postal_code.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.doctor_postal_code.required">Please enter postal code</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                        <div class="form-group row">
                          <div class="col-sm-12">
                            <label class="col-form-label">Description(Optional)</label>
                            <textarea rows="2" type="text"
                                        class="form-control"
                                        v-model="$v.typeform.desc.$model"
                                        name="desc" placeholder="Description..."
                                        :class="{ 'is-invalid':  $v.typeform.desc.$error }"/>
                            <div class="mt-1">
                              <small class="text-muted">This description will display on landing page below doctor name</small>
                            </div>
                          </div>
                        </div>
                  </div>


                  <div class="col-md-3">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Username</label>
                        <input v-if="$route.params.id" type="text" class="form-control"
                                v-model="$v.typeform.username.$model"
                                name="username" placeholder="Username"
                                :class="{ 'is-invalid':  $v.typeform.username.$error  }" disabled/>
                        <input v-else type="text" class="form-control"
                                v-model="$v.typeform.username.$model"
                                name="username" placeholder="Username"
                                :class="{ 'is-invalid':  $v.typeform.username.$error  }"/>
                        <div v-if="$v.typeform.username.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.username.required">Please enter username</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3" v-if="!$route.params.id">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Password</label>
                        <div class="input-group">
                            <input :type="show ? 'text' : 'password'"  class="form-control"
                                    v-model="$v.typeform.password.$model"
                                    name="password" id="password" placeholder="Password"
                                    :class="{ 'is-invalid':  $v.typeform.password.$error  }"/>
                            <span class="input-group-text">
                                <i :class="show ? 'mdi mdi-eye-off' : 'mdi mdi-eye'" id="togglePassword"
                               style="cursor: pointer" @mousedown="show = !show" @mouseup="show = !show" @touchstart="show = !show" @touchend="show = !show" ></i>
                            </span>
                        </div>
                        <div v-if="!$route.params.id && $v.typeform.password.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.password.required">Please enter password</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3" v-if="!$route.params.id"><!---->
                    <label class="col-form-label">Role</label>
                    <div class="col-sm-12">
                      <div class="col-sm-10">
                        <multiselect v-model="role_selected" :options="option_role"
                                      :multiple="false" :close-on-select="true" :clear-on-select="false"
                                      :preserve-search="true" placeholder="Select Role" label="role_name"
                                      track-by="role_name" :preselect-first="true" :showLabels="false"></multiselect>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Status</label>
                        <multiselect v-model="$v.typeform.doctor_status.$model" :options="status"
                                   :multiple="false" :close-on-select="true" :clear-on-select="false"
                                   :preserve-search="true" placeholder="Status"
                                   :preselect-first="true" :showLabels="false"></multiselect>
                        <div v-if="$v.typeform.doctor_status.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.doctor_status.required">Please select status</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <br/><br/>

    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Permissions</h4>
                    <div class="row justify-content-between">
                      <div class="col-md-5">
                          <div class="row">
                            <div class="col-md-6">
                              <label class="col-form-label">All Permissions</label>
                            </div>
                            <div class="col-md-3">
                              <div class="form-check">
                                <label class="form-check-label">
                                <input class="checkbox" type="checkbox" id="permission_all_read" v-model="permission_all_read" @change="allPermissionRead(true)">Read<i class="input-helper"></i></label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-check">
                                <label class="form-check-label">
                                <input class="checkbox" type="checkbox" id="permission_all_write" v-model="permission_all_write"  @change="allPermissionRead(false)">Write<i class="input-helper"></i></label>
                            </div>
                        </div>
                          </div>
                      </div>
                        <div class="col-md-5">
                          <div class="row">
                            <div class="col-md-6">
                                <label class="col-form-label">Dashboard</label>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <label class="form-check-label">
                                    <input class="checkbox" type="checkbox" id="permission_dashboard_read" v-model="permission_dashboard_read" @change="checkAllPermission(true)">Read<i class="input-helper"></i></label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <label class="form-check-label">
                                    <input class="checkbox" type="checkbox" v-model="permission_dashboard_write" @change="checkAllPermission(false)">Write<i class="input-helper"></i></label>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div class="col-md-5">
                            <div class="row">
                                <div class="col-md-6">
                                    <label class="col-form-label">OPD</label>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-check">
                                        <label class="form-check-label">
                                        <input class="checkbox" type="checkbox" v-model="permission_opd_read"  @change="checkAllPermission(true)">Read<i class="input-helper"></i></label>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-check">
                                        <label class="form-check-label">
                                        <input class="checkbox" type="checkbox" v-model="permission_opd_write" @change="checkAllPermission(false)">Write<i class="input-helper"></i></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="row">
                                <div class="col-md-6">
                                    <label class="col-form-label">IPD</label>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-check">
                                        <label class="form-check-label">
                                        <input class="checkbox" type="checkbox" v-model="permission_ipd_read"  @change="checkAllPermission(true)">Read<i class="input-helper"></i></label>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-check">
                                        <label class="form-check-label">
                                        <input class="checkbox" type="checkbox" v-model="permission_ipd_write" @change="checkAllPermission(false)">Write<i class="input-helper"></i></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5">
                          <div class="row">
                            <div class="col-md-6">
                                <label class="col-form-label">Appointment</label>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <label class="form-check-label">
                                    <input class="checkbox" type="checkbox" v-model="permission_appointment_read" @change="checkAllPermission(true)">Read<i class="input-helper"></i></label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <label class="form-check-label">
                                    <input class="checkbox" type="checkbox" v-model="permission_appointment_write" @change="checkAllPermission(false)">Write<i class="input-helper"></i></label>
                                </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-5">
                          <div class="row">
                            <div class="col-md-6">
                                <label class="col-form-label">Patient</label>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <label class="form-check-label">
                                    <input class="checkbox" type="checkbox" v-model="permission_patient_read" @change="checkAllPermission(true)">Read<i class="input-helper"></i></label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <label class="form-check-label">
                                    <input class="checkbox" type="checkbox" v-model="permission_patient_write" @change="checkAllPermission(false)">Write<i class="input-helper"></i></label>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div class="col-md-5">
                          <div class="row">
                            <div class="col-md-6">
                                <label class="col-form-label">Prescription</label>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <label class="form-check-label">
                                    <input class="checkbox" type="checkbox" v-model="permission_prescription_read" @change="checkAllPermission(true)">Read<i class="input-helper"></i></label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <label class="form-check-label">
                                    <input class="checkbox" type="checkbox" v-model="permission_prescription_write" @change="checkAllPermission(false)">Write<i class="input-helper"></i></label>
                                </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-5">
                          <div class="row">
                            <div class="col-md-6">
                                <label class="col-form-label">User Management</label>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <label class="form-check-label">
                                    <input class="checkbox" type="checkbox" v-model="permission_doctor_read" @change="checkAllPermission(true)">Read<i class="input-helper"></i></label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <label class="form-check-label">
                                    <input class="checkbox" type="checkbox" v-model="permission_doctor_write" @change="checkAllPermission(false)">Write<i class="input-helper"></i></label>
                                </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-5">
                          <div class="row">
                            <div class="col-md-6">
                                <label class="col-form-label">Medicine Management</label>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <label class="form-check-label">
                                    <input class="checkbox" type="checkbox" v-model="permission_medicine_read" @change="checkAllPermission(true)">Read<i class="input-helper"></i></label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <label class="form-check-label">
                                    <input class="checkbox" type="checkbox" v-model="permission_medicine_write" @change="checkAllPermission(false)">Write<i class="input-helper"></i></label>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div class="col-md-5">
                            <div class="row">
                                <div class="col-md-6">
                                    <label class="col-form-label">Room/Ward</label>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-check">
                                        <label class="form-check-label">
                                        <input class="checkbox" type="checkbox" v-model="permission_room_ward_read"  @change="checkAllPermission(true)">Read<i class="input-helper"></i></label>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-check">
                                        <label class="form-check-label">
                                        <input class="checkbox" type="checkbox" v-model="permission_room_ward_write" @change="checkAllPermission(false)">Write<i class="input-helper"></i></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="row">
                                <div class="col-md-6">
                                    <label class="col-form-label">Request/Feedbacks</label>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-check">
                                        <label class="form-check-label">
                                        <input class="checkbox" type="checkbox" v-model="permission_contact_us_read"  @change="checkAllPermission(true)">Read<i class="input-helper"></i></label>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-check">
                                        <label class="form-check-label">
                                        <input class="checkbox" type="checkbox" v-model="permission_contact_us_write" @change="checkAllPermission(false)">Write<i class="input-helper"></i></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5">
                          <div class="row">
                            <div class="col-md-6">
                                <label class="col-form-label">City Management</label>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <label class="form-check-label">
                                    <input class="checkbox" type="checkbox" v-model="permission_city_read" @change="checkAllPermission(true)">Read<i class="input-helper"></i></label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <label class="form-check-label">
                                    <input class="checkbox" type="checkbox" v-model="permission_city_write" @change="checkAllPermission(false)">Write<i class="input-helper"></i></label>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div class="col-md-5">
                          <div class="row">
                        <div class="col-md-6">
                            <label class="col-form-label">Report Management</label>
                        </div>
                        <div class="col-md-3">
                            <div class="form-check">
                                <label class="form-check-label">
                                <input class="checkbox" type="checkbox" v-model="permission_report_opd_read" @change="checkAllPermission(true)">Read<i class="input-helper"></i></label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-check">
                                <label class="form-check-label">
                                <input class="checkbox" type="checkbox" v-model="permission_report_opd_write" @change="checkAllPermission(false)">Write<i class="input-helper"></i></label>
                            </div>
                        </div>
                    </div>
                        </div>
                        <div class="col-md-5">
                          <div class="row">
                        <div class="col-md-6">
                            <label class="col-form-label">Effect Management</label>
                        </div>
                        <div class="col-md-3">
                            <div class="form-check">
                                <label class="form-check-label">
                                <input class="checkbox" type="checkbox" v-model="permission_effect_opd_read" @change="checkAllPermission(true)">Read<i class="input-helper"></i></label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-check">
                                <label class="form-check-label">
                                <input class="checkbox" type="checkbox" v-model="permission_effect_opd_write" @change="checkAllPermission(false)">Write<i class="input-helper"></i></label>
                            </div>
                        </div>
                    </div>
                        </div>
                        <div class="col-md-5">
                          <div class="row">
                        <div class="col-md-6">
                            <label class="col-form-label">Department Management</label>
                        </div>
                        <div class="col-md-3">
                            <div class="form-check">
                                <label class="form-check-label">
                                <input class="checkbox" type="checkbox" v-model="permission_department_read" @change="checkAllPermission(true)">Read<i class="input-helper"></i></label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-check">
                                <label class="form-check-label">
                                <input class="checkbox" type="checkbox" v-model="permission_department_write" @change="checkAllPermission(false)">Write<i class="input-helper"></i></label>
                            </div>
                        </div>
                    </div>
                        </div>
                        <div class="col-md-5">
                          <div class="row">
                        <div class="col-md-6">
                            <label class="col-form-label">Statistics</label>
                        </div>
                        <div class="col-md-3">
                            <div class="form-check">
                                <label class="form-check-label">
                                <input class="checkbox" type="checkbox" v-model="permission_report_read" @change="checkAllPermission(true)">Read<i class="input-helper"></i></label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-check">
                                <label class="form-check-label">
                                <input class="checkbox" type="checkbox" v-model="permission_report_write" @change="checkAllPermission(false)">Write<i class="input-helper"></i></label>
                            </div>
                        </div>
                    </div>
                        </div>
                        <div class="col-md-5">
                          <div class="row">
                        <div class="col-md-6">
                            <label class="col-form-label">Settings</label>
                        </div>
                        <div class="col-md-3">
                            <div class="form-check">
                                <label class="form-check-label">
                                <input class="checkbox" type="checkbox" v-model="permission_settings_read" @change="checkAllPermission(true)">Read<i class="input-helper"></i></label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-check">
                                <label class="form-check-label">
                                <input class="checkbox" type="checkbox" v-model="permission_settings_write" @change="checkAllPermission(false)">Write<i class="input-helper"></i></label>
                            </div>
                        </div>
                    </div>
                        </div>
                    <div class="col-md-5">
                        <div class="row">
                            <div class="col-md-6">
                                <label class="col-form-label">Changelog</label>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <label class="form-check-label">
                                    <input class="checkbox" type="checkbox" v-model="permission_changelog_read" @change="checkAllPermission(true)">Read<i class="input-helper"></i></label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <label class="form-check-label">
                                    <input class="checkbox" type="checkbox" v-model="permission_changelog_write" @change="checkAllPermission(false)">Write<i class="input-helper"></i></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="row">
                            <div class="col-md-6">
                                <label class="col-form-label">Admin</label>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <label class="form-check-label">
                                    <input class="checkbox" type="checkbox" v-model="permission_admin_read" @change="checkAllPermission(true)">Read<i class="input-helper"></i></label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <label class="form-check-label">
                                    <input class="checkbox" type="checkbox" v-model="permission_admin_write" @change="checkAllPermission(false)">Write<i class="input-helper"></i></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="row">
                            <div class="col-md-6">
                                <label class="col-form-label">Web Settings</label>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <label class="form-check-label">
                                    <input class="checkbox" type="checkbox" v-model="permission_settings_web_read" @change="checkAllPermission(true)">Read<i class="input-helper"></i></label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <label class="form-check-label">
                                    <input class="checkbox" type="checkbox" v-model="permission_settings_web_write" @change="checkAllPermission(false)">Write<i class="input-helper"></i></label>
                                </div>
                            </div>
                        </div>
                    </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
      <br/><br/>
      <div class="col-md-12">
          <b-button v-if="is_btn_spinner" variant="primary" disabled>
              <b-spinner small type="grow"></b-spinner>Loading...
          </b-button>
          <button v-if="this.$route.name == 'adddoctor' && !is_btn_spinner" type="button" @click="submitForm()" class="btn btn-gradient-primary me-2">Submit</button>
          <button v-if="this.$route.name == 'editdoctor' && !is_btn_spinner" type="button" @click="editSubmitForm($route.params.id)" class="btn btn-gradient-primary me-2">Submit</button>
          <!-- <button class="btn btn-light">Cancel</button> -->
      </div>
    </div>
    <Footer/>
  </div>
</div>
</div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import { required, numeric } from "vuelidate/lib/validators";
import { mapActions} from "vuex";
import Multiselect from 'vue-multiselect';
import string from "../constant/strings.js";
import DateRangePicker from 'vue2-daterange-picker';
import moment from "moment";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
    name:'AddDOCTOR',
    title: string.PAGE_TITLE_ADD_USER,
    components: {
      NavBar,
      Footer,
      SideBar,
      Multiselect,
      DateRangePicker,
      DatePicker,
    },
    data() {

    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const maxDate = new Date(today)

    return {
      img_progress: false,
      permission_all_read: false,
      permission_all_write: false,
      permission_dashboard_read: false,
      permission_dashboard_write: false,
      permission_opd_read: false,
      permission_opd_write: false,
      permission_prescription_read: false,
      permission_prescription_write: false,
      permission_patient_read: false,
      permission_patient_write: false,
      permission_appointment_read: false,
      permission_appointment_write: false,
      permission_doctor_read: false,
      permission_doctor_write: false,
      permission_medicine_read: false,
      permission_medicine_write: false,
      permission_city_read: false,
      permission_city_write: false,
      permission_report_read: false,
      permission_report_write: false,
      permission_settings_read: false,
      permission_settings_write: false,
      permission_changelog_read: false,
      permission_changelog_write: false,
      permission_admin_read: false,
      permission_admin_write: false,
      permission_report_opd_read: false,
      permission_report_opd_write: false,
      permission_effect_opd_read: false,
      permission_effect_opd_write: false,
      permission_department_read: false,
      permission_department_write: false,
      permission_ipd_write: false,
      permission_ipd_read: false,
      permission_contact_us_read: false,
      permission_contact_us_write: false,
      permission_room_ward_write: false,
      permission_room_ward_read: false,
      permission_settings_web_read: false,
      permission_settings_web_write: false,
      option_role:[{"id":"1","role_name":"Admin"},{"id":"2","role_name":"Doctor"},{"id":"3","role_name":"Compounder"}],
      selected_department_id:[],
      birth_date:'',
      role_selected:{"id":"1","role_name":"Admin"},
      gender_list:['Male', 'Female'],
      marital_status_list:["Yes", "No"],
      blood_group_list:["A-", "B+", "B-", "AB+", "AB-", "O+", "O-"],
      status:["Active", "Inactive"],
      show:false,
      profile_pic: '',
      profile_pic_file: null,
      typeform:{
        doctor_name:'',
        doctor_mobile_number:'',
        identification:'',
        doctor_status:'',
        doctor_email:'',
        doctor_address:'',
        doctor_city:'',
        doctor_postal_code:'',
        doctor_emergency_contact_number:'',
        marital_status:'',
        username:'',
        gender:'',
        blood_group:'',
        birth_date:'',
        password:'',
        desc:'',
        selected_department_id:[],
        today_date: now.getFullYear() + '-' + (now.getMonth() + 1) + '-'  + now.getDate(),
        picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        max: maxDate,
        permission_all_read: false,
        permission_all_write: false,
        permission_dashboard_read: false,
        permission_dashboard_write: false,
        permission_opd_read: false,
        permission_opd_write: false,
        permission_prescription_read: false,
        permission_prescription_write: false,
        permission_patient_read: false,
        permission_patient_write: false,
        permission_appointment_read: false,
        permission_appointment_write: false,
        permission_doctor_read: false,
        permission_doctor_write: false,
        permission_medicine_read: false,
        permission_medicine_write: false,
        permission_city_read: false,
        permission_city_write: false,
        permission_report_read: false,
        permission_report_write: false,
        permission_settings_read: false,
        permission_settings_write: false,
        permission_changelog_read: false,
        permission_changelog_write: false,
        permission_admin_read: false,
        permission_admin_write: false,
        permission_report_opd_read: false,
        permission_report_opd_write: false,
        permission_effect_opd_read: false,
        permission_effect_opd_write: false,
        permission_department_read: false,
        permission_department_write: false,
        permission_ipd_write: false,
        permission_ipd_read: false,
        permission_contact_us_read: false,
        permission_contact_us_write: false,
        permission_room_ward_write: false,
        permission_room_ward_read: false,
        permission_settings_web_read: false,
        permission_settings_web_write: false,
      },
      departments:[],
      department_selected:[],
      MAXDATE:maxDate,
      is_btn_spinner:false,
    }
    },
    validations: {
    typeform: {
      doctor_name:{required},
      doctor_mobile_number:{required,numeric},
      identification:{required},
      selected_department_id:{required},
      doctor_email:{required},
      doctor_status:{required},
      doctor_address:{required},
      doctor_city:{required},
      doctor_postal_code:{required},
      doctor_emergency_contact_number:{required},
      marital_status:{required},
      gender:{required},
      birth_date:{required},
      blood_group:{required},
      username:{required},
      password:{},
      desc:{},
      permissions:{required},
    },
  },
  mounted(){        
    if(this.$route.params.id){
      this.getDoctor(this.$route.params.id)
    } else {
      this.getDepartment();
    }
  },

  methods:{
    ...mapActions("hospital",["postAddDoctorData","getDoctorsDetailsData","editDoctorDetailsData", "getOPDDepartmentListData", 'deleteProfilePicData']),
    
    editSubmitForm(id){
      this.$v.$touch();

      this.typeform.selected_department_id = [];
      if(this.department_selected && this.department_selected.length > 0){
        this.department_selected.forEach((department_obj) => {
              this.typeform.selected_department_id.push(department_obj.id);
        })
      }
      this.typeform.selected_department_id = JSON.stringify(this.typeform.selected_department_id);
        console.log(this.typeform.gender)
      if (!this.department_selected.length > 0) {
        this.$toasted.error("Please select department", {duration: 2000,});
        return;
      } else if (!this.typeform.gender) {
        this.$toasted.error("Please select gender", {duration: 2000,});
        return;
      } else if(!this.typeform.marital_status) {
        this.$toasted.error("Please select marital status", {duration: 2000,});
        return;
      } else if(!this.typeform.birth_date) {
        this.$toasted.error("Please select birth date", {duration: 2000,});
        return;
      } else if(!this.typeform.blood_group) {
        this.$toasted.error("Please select blood group", {duration: 2000,});
        return;
      } else if(!this.typeform.doctor_status) {
        this.$toasted.error("Please select status", {duration: 2000,});
        return;
      }

      this.typeform.permissions = {
        "dashboard_read": this.getAllowedPermission("dashboard_read"),
        "dashboard_write": this.getAllowedPermission("dashboard_write"),
        "opd_read": this.getAllowedPermission("opd_read"),
        "opd_write": this.getAllowedPermission("opd_write"),
        "prescription_read": this.getAllowedPermission("prescription_read"),
        "prescription_write": this.getAllowedPermission("prescription_write"),
        "patient_read": this.getAllowedPermission("patient_read"),
        "patient_write": this.getAllowedPermission("patient_write"),
        "appointment_read": this.getAllowedPermission("appointment_read"),
        "appointment_write": this.getAllowedPermission("appointment_write"),
        "doctor_read": this.getAllowedPermission("doctor_read"),
        "doctor_write": this.getAllowedPermission("doctor_write"),
        "medicine_read": this.getAllowedPermission("medicine_read"),
        "medicine_write": this.getAllowedPermission("medicine_write"),
        "city_read": this.getAllowedPermission("city_read"),
        "city_write": this.getAllowedPermission("city_write"),
        "report_read": this.getAllowedPermission("report_read"),
        "report_write": this.getAllowedPermission("report_write"),
        "settings_read": this.getAllowedPermission("settings_read"),
        "settings_write": this.getAllowedPermission("settings_write"),
        "changelog_read": this.getAllowedPermission("changelog_read"),
        "changelog_write": this.getAllowedPermission("changelog_write"),
        "admin_read": this.getAllowedPermission("admin_read"),
        "admin_write": this.getAllowedPermission("admin_write"),
        "report_opd_read": this.getAllowedPermission("report_opd_read"),
        "report_opd_write": this.getAllowedPermission("report_opd_write"),
        "effect_opd_read": this.getAllowedPermission("effect_opd_read"),
        "effect_opd_write": this.getAllowedPermission("effect_opd_write"),
        "department_read": this.getAllowedPermission("department_read"),
        "department_write": this.getAllowedPermission("department_write"),
        "client_contact_us_write": this.getAllowedPermission("client_contact_us_write"),
        "client_contact_us_read": this.getAllowedPermission("client_contact_us_read"),
        "room_ward_write": this.getAllowedPermission("room_ward_write"),
        "room_ward_read": this.getAllowedPermission("room_ward_read"),
        "ipd_read": this.getAllowedPermission("ipd_read"),
        "ipd_write": this.getAllowedPermission("ipd_write"),
        "settings_web_read": this.getAllowedPermission("settings_web_read"),
        "settings_web_write": this.getAllowedPermission("settings_web_write")
      }
      let selected_role_id = this.role_selected.id;

      if(this.typeform.password.length == 0){
        this.typeform.password = '123456'
      }

      if (!this.$v.typeform.$invalid) {
        this.is_btn_spinner = true;
        var bodyFormData = new FormData();      
        bodyFormData.append('doctor_id', id);
        bodyFormData.append('doctor_name', this.typeform.doctor_name);
        bodyFormData.append('doctor_mobile_number', this.typeform.doctor_mobile_number);
        bodyFormData.append('doctor_identification', this.typeform.identification);
        bodyFormData.append('doctor_email', this.typeform.doctor_email);
        bodyFormData.append('doctor_address', this.typeform.doctor_address);
        bodyFormData.append('doctor_city', this.typeform.doctor_city);
        bodyFormData.append('doctor_postal_code', this.typeform.doctor_postal_code);
        bodyFormData.append('doctor_emergency_contact_number', this.typeform.doctor_emergency_contact_number);
        bodyFormData.append('marital_status', this.typeform.marital_status);
        bodyFormData.append('gender', this.typeform.gender);
        bodyFormData.append('birth_date', moment(this.typeform.birth_date).format('YYYY-MM-DD'))
        bodyFormData.append('blood_group', this.typeform.blood_group);
        bodyFormData.append('username', this.typeform.username);
        bodyFormData.append('password', this.typeform.password);
        bodyFormData.append('role', selected_role_id);
        bodyFormData.append('department', this.typeform.selected_department_id);
        bodyFormData.append('permissions', JSON.stringify(this.typeform.permissions));
        bodyFormData.append('doctor_status', this.typeform.doctor_status);
        bodyFormData.append('profile_pic', this.profile_pic_file);
        bodyFormData.append('desc', this.typeform.desc);
        console.log(bodyFormData)
        this.editDoctorDetailsData(bodyFormData).then((response) => {
          this.is_btn_spinner = false;
          if (response.response_code == 200) {
            this.$toasted.success(response.message, {duration: 2000,});
            this.$router.push({ name: 'doctor' });
          }else{
            this.$toasted.error(response.message, {duration: 2000,});
          }
        });
      }
    },

    submitForm(){
      this.$v.$touch();
      this.typeform.selected_department_id = [];
      if(this.department_selected && this.department_selected.length > 0){
        this.department_selected.forEach((department_obj) => {
        this.typeform.selected_department_id.push(department_obj.id);
        })
      }
      this.typeform.selected_department_id = JSON.stringify(this.typeform.selected_department_id);

      if (!this.department_selected.length > 0) {
        this.$toasted.error("Please select department", {duration: 2000,});
        return;
      } else if (!this.typeform.gender) {
        this.$toasted.error("Please select gender", {duration: 2000,});
        return;
      } else if(!this.typeform.marital_status) {
        this.$toasted.error("Please select marital status", {duration: 2000,});
        return;
      } else if(!this.typeform.birth_date) {
        this.$toasted.error("Please select birth date", {duration: 2000,});
        return;
      } else if(!this.typeform.blood_group) {
        this.$toasted.error("Please select blood group", {duration: 2000,});
        return;
      } else if(!this.role_selected || !this.role_selected.id) {
        this.$toasted.error("Please select role", {duration: 2000,});
        return;
      } else if(!this.typeform.doctor_status) {
        this.$toasted.error("Please select status", {duration: 2000,});
        return;
      }


      this.typeform.permissions = {
        "dashboard_read": this.getAllowedPermission("dashboard_read"),
        "dashboard_write": this.getAllowedPermission("dashboard_write"),
        "opd_read": this.getAllowedPermission("opd_read"),
        "opd_write": this.getAllowedPermission("opd_write"),
        "prescription_read": this.getAllowedPermission("prescription_read"),
        "prescription_write": this.getAllowedPermission("prescription_write"),
        "patient_read": this.getAllowedPermission("patient_read"),
        "patient_write": this.getAllowedPermission("patient_write"),
        "appointment_read": this.getAllowedPermission("appointment_read"),
        "appointment_write": this.getAllowedPermission("appointment_write"),
        "doctor_read": this.getAllowedPermission("doctor_read"),
        "doctor_write": this.getAllowedPermission("doctor_write"),
        "medicine_read": this.getAllowedPermission("medicine_read"),
        "medicine_write": this.getAllowedPermission("medicine_write"),
        "city_read": this.getAllowedPermission("city_read"),
        "city_write": this.getAllowedPermission("city_write"),
        "report_read": this.getAllowedPermission("report_read"),
        "report_write": this.getAllowedPermission("report_write"),
        "settings_read": this.getAllowedPermission("settings_read"),
        "settings_write": this.getAllowedPermission("settings_write"),
        "changelog_read": this.getAllowedPermission("changelog_read"),
        "changelog_write": this.getAllowedPermission("changelog_write"),
        "admin_read": this.getAllowedPermission("admin_read"),
        "admin_write": this.getAllowedPermission("admin_write"),
        "report_opd_read": this.getAllowedPermission("report_opd_read"),
        "report_opd_write": this.getAllowedPermission("report_opd_write"),
        "effect_opd_read": this.getAllowedPermission("effect_opd_read"),
        "effect_opd_write": this.getAllowedPermission("effect_opd_write"),
        "department_read": this.getAllowedPermission("department_read"),
        "department_write": this.getAllowedPermission("department_write"),
        "client_contact_us_write": this.getAllowedPermission("client_contact_us_write"),
        "client_contact_us_read": this.getAllowedPermission("client_contact_us_read"),
        "room_ward_write": this.getAllowedPermission("room_ward_write"),
        "room_ward_read": this.getAllowedPermission("room_ward_read"),
        "ipd_read": this.getAllowedPermission("ipd_read"),
        "ipd_write": this.getAllowedPermission("ipd_write"),
        "settings_web_read": this.getAllowedPermission("settings_web_read"),
        "settings_web_write": this.getAllowedPermission("settings_web_write")
      }
      let selected_role_id = this.role_selected.id;

      if(this.typeform.password.length == 0){
        this.typeform.password = '123456'
      }
      if (!this.$v.typeform.$invalid) {
      this.is_btn_spinner = true;

      var bodyFormData = new FormData();
      bodyFormData.append('doctor_name', this.typeform.doctor_name);
      bodyFormData.append('doctor_mobile_number', this.typeform.doctor_mobile_number);
      bodyFormData.append('doctor_identification', this.typeform.identification);
      bodyFormData.append('doctor_email', this.typeform.doctor_email);
      bodyFormData.append('doctor_address', this.typeform.doctor_address);
      bodyFormData.append('doctor_city', this.typeform.doctor_city);
      bodyFormData.append('doctor_postal_code', this.typeform.doctor_postal_code);
      bodyFormData.append('doctor_emergency_contact_number', this.typeform.doctor_emergency_contact_number);
      bodyFormData.append('marital_status', this.typeform.marital_status);
      bodyFormData.append('gender', this.typeform.gender);
      bodyFormData.append('birth_date', moment(this.typeform.birth_date).format('YYYY-MM-DD'))
      bodyFormData.append('blood_group', this.typeform.blood_group);
      bodyFormData.append('username', this.typeform.username);
      bodyFormData.append('password', this.typeform.password);
      bodyFormData.append('role', selected_role_id);
      bodyFormData.append('department', this.typeform.selected_department_id);
      bodyFormData.append('permissions', JSON.stringify(this.typeform.permissions));
      bodyFormData.append('doctor_status', this.typeform.doctor_status);
      bodyFormData.append('profile_pic', this.profile_pic_file);
      bodyFormData.append('desc', this.typeform.desc);

      this.postAddDoctorData(bodyFormData).then((response) => {
        this.is_btn_spinner = false;
        if (response.response_code == 200) {
          this.$toasted.success("Doctor added successfully", {duration: 2000,});
          this.$router.push({ name: 'doctor' });
        }else{
          this.$toasted.error(response.message, {duration: 2000,});
        }
      });
      }
    },
    getDepartment(){
        var bodyFormData = new FormData();
          this.getOPDDepartmentListData(bodyFormData).then((response) => {
            if (response.response_code == 200) {
                this.departments = response.data;
            }else{
              this.$router.push({ name: 'doctor' });
            }
        });
    },
    getDoctor(id){
      var bodyFormData = new FormData();      
      bodyFormData.append('doctor_id', id);
      this.getDoctorsDetailsData(bodyFormData).then((response) => {        
        if (response.response_code == 200) {          
            this.getDepartment();
            this.profile_pic = response.data.profile_pic
            this.typeform= {
              doctor_name:response.data.name,
              doctor_mobile_number:response.data.mobile,
              identification:response.data.identification_number,
              department:'',
              doctor_status : response.data.status,
              doctor_email : response.data.email,
              doctor_address : response.data.address,
              doctor_city : response.data.doctor_city,
              doctor_postal_code : response.data.doctor_postal_code,
              doctor_emergency_contact_number : response.data.doctor_emergency_contact_number,
              marital_status : response.data.marital_status,
              username : response.data.username,
              gender : response.data.gender,
              birth_date : new Date(response.data.birth_date),
              blood_group : response.data.blood_group,
              password:'',
              desc:response.data.desc,
            }

            this.permission_dashboard_read = response.permission.dashboard_read;
            this.permission_dashboard_write = response.permission.dashboard_write;
            this.permission_opd_read = response.permission.opd_read;
            this.permission_opd_write = response.permission.opd_write;
            this.permission_prescription_read = response.permission.prescription_read;
            this.permission_prescription_write = response.permission.prescription_write;
            this.permission_patient_read = response.permission.patient_read;
            this.permission_patient_write = response.permission.patient_write;
            this.permission_appointment_read = response.permission.appointment_read;
            this.permission_appointment_write = response.permission.appointment_write;
            this.permission_doctor_read = response.permission.doctor_read;
            this.permission_doctor_write = response.permission.doctor_write;
            this.permission_medicine_read = response.permission.medicine_read;
            this.permission_medicine_write = response.permission.medicine_write;
            this.permission_city_read = response.permission.city_read;
            this.permission_city_write = response.permission.city_write;
            this.permission_report_read = response.permission.report_read;
            this.permission_report_write = response.permission.report_write;
            this.permission_settings_read = response.permission.settings_read;
            this.permission_settings_write = response.permission.settings_write;
            this.permission_changelog_read = response.permission.changelog_read;
            this.permission_changelog_write = response.permission.changelog_write;
            this.permission_admin_read = response.permission.admin_read;
            this.permission_admin_write = response.permission.admin_write;
            this.permission_report_opd_read = response.permission.report_opd_read;
            this.permission_report_opd_write = response.permission.report_opd_write;
            this.permission_effect_opd_read = response.permission.effect_opd_read;
            this.permission_effect_opd_write = response.permission.effect_opd_write;
            this.permission_department_read = response.permission.department_read;
            this.permission_department_write = response.permission.department_write;

            this.permission_contact_us_write = response.permission.client_contact_us_write;
            this.permission_contact_us_read = response.permission.client_contact_us_read;
            this.permission_room_ward_write = response.permission.room_ward_write;
            this.permission_room_ward_read = response.permission.room_ward_read;
            this.permission_ipd_read = response.permission.ipd_read;
            this.permission_ipd_write = response.permission.ipd_write;
            this.permission_settings_web_read = response.permission.settings_web_read;
            this.permission_settings_web_write = response.permission.settings_web_write;

            this.checkAllPermission(true)
            this.checkAllPermission(false)

            if(response.data.department.length > 0) {
                this.department_selected = response.data.department;
            }
        }else{
          this.$router.push({ name: 'doctor' });
        }
      });
    },
    allPermissionRead(isRead) {
        if(isRead) {
            this.permission_dashboard_read = this.permission_all_read;
            this.permission_opd_read = this.permission_all_read;
            this.permission_prescription_read = this.permission_all_read;
            this.permission_patient_read = this.permission_all_read;
            this.permission_appointment_read = this.permission_all_read;
            this.permission_doctor_read = this.permission_all_read;
            this.permission_medicine_read = this.permission_all_read;
            this.permission_city_read = this.permission_all_read;
            this.permission_report_read = this.permission_all_read;
            this.permission_settings_read = this.permission_all_read;
            this.permission_changelog_read = this.permission_all_read;
            this.permission_admin_read = this.permission_all_read;
            this.permission_report_opd_read = this.permission_all_read;
            this.permission_effect_opd_read = this.permission_all_read;
            this.permission_department_read = this.permission_all_read;
            this.permission_ipd_read = this.permission_all_read;
            this.permission_contact_us_read = this.permission_all_read;
            this.permission_room_ward_read = this.permission_all_read;
            this.permission_settings_web_read = this.permission_all_read;
        } else {
            this.permission_dashboard_write = this.permission_all_write;
            this.permission_opd_write = this.permission_all_write;
            this.permission_prescription_write = this.permission_all_write;
            this.permission_patient_write = this.permission_all_write;
            this.permission_appointment_write = this.permission_all_write;
            this.permission_doctor_write = this.permission_all_write;
            this.permission_medicine_write = this.permission_all_write;
            this.permission_city_write = this.permission_all_write;
            this.permission_report_write = this.permission_all_write;
            this.permission_settings_write = this.permission_all_write;
            this.permission_changelog_write = this.permission_all_write;
            this.permission_admin_write = this.permission_all_write;
            this.permission_report_opd_write = this.permission_all_write;
            this.permission_effect_opd_write = this.permission_all_write;
            this.permission_department_write = this.permission_all_write;
            this.permission_ipd_write = this.permission_all_write;
            this.permission_contact_us_write = this.permission_all_write;
            this.permission_room_ward_write = this.permission_all_write;
            this.permission_settings_web_write = this.permission_all_write;
        }
    },
    checkAllPermission(isRead) {
        if(isRead) {
            this.permission_all_read = this.permission_dashboard_read && this.permission_opd_read &&
                            this.permission_prescription_read && this.permission_patient_read && this.permission_appointment_read &&
                            this.permission_doctor_read && this.permission_medicine_read &&
                            this.permission_city_read && this.permission_report_read && this.permission_settings_read &&
                            this.permission_changelog_read && this.permission_admin_read && this.permission_report_opd_read &&
                            this.permission_effect_opd_read && this.permission_department_read &&
                            this.permission_ipd_read && this.permission_contact_us_read  &&
                            this.permission_room_ward_read && this.permission_settings_web_read;
        } else {
            this.permission_all_write = this.permission_dashboard_write && this.permission_opd_write &&
                            this.permission_prescription_write && this.permission_patient_write && this.permission_appointment_write &&
                            this.permission_doctor_write && this.permission_medicine_write &&
                            this.permission_city_write && this.permission_report_write && this.permission_settings_write &&
                            this.permission_changelog_write && this.permission_admin_write && this.permission_report_opd_write &&
                            this.permission_effect_opd_write && this.permission_department_write &&
                            this.permission_ipd_write && this.permission_contact_us_write &&
                            this.permission_room_ward_write && this.permission_settings_web_write;
        }
    },
    getAllowedPermission(permissionType) {
         if(permissionType == "dashboard_read") {
            return this.permission_all_read || this.permission_dashboard_read;
         } else if(permissionType == "dashboard_write") {
            return this.permission_all_write || this.permission_dashboard_write;
         }
         else if(permissionType == "opd_read") {
            return this.permission_all_read || this.permission_opd_read;
         }
         else if(permissionType == "opd_write") {
            return this.permission_all_write || this.permission_opd_write;
         }
         else if(permissionType == "prescription_read") {
            return this.permission_all_read || this.permission_prescription_read;
         }
         else if(permissionType == "prescription_write") {
            return this.permission_all_write || this.permission_prescription_write;
         }
         else if(permissionType == "patient_read") {
            return this.permission_all_read || this.permission_patient_read;
         }
         else if(permissionType == "patient_write") {
            return this.permission_all_write || this.permission_patient_write;
         }
         else if(permissionType == "appointment_read") {
            return this.permission_all_read || this.permission_appointment_read;
         }
         else if(permissionType == "appointment_write") {
            return this.permission_all_write || this.permission_appointment_write;
         }
         else if(permissionType == "doctor_read") {
            return this.permission_all_read || this.permission_doctor_read;
         }
         else if(permissionType == "doctor_write") {
            return this.permission_all_write || this.permission_doctor_write;
         }
         else if(permissionType == "medicine_read") {
            return this.permission_all_read || this.permission_medicine_read;
         }
         else if(permissionType == "medicine_write") {
            return this.permission_all_write || this.permission_medicine_write;
         }
         else if(permissionType == "city_read") {
            return this.permission_all_read || this.permission_city_read;
         }
         else if(permissionType == "city_write") {
            return this.permission_all_write || this.permission_city_write;
         }
         else if(permissionType == "report_read") {
            return this.permission_all_read || this.permission_report_read;
         }
         else if(permissionType == "report_write") {
            return this.permission_all_write || this.permission_report_write;
         }
         else if(permissionType == "settings_read") {
            return this.permission_all_read || this.permission_settings_read;
         }
         else if(permissionType == "settings_write") {
            return this.permission_all_write || this.permission_settings_write;
         }
         else if(permissionType == "changelog_read") {
            return this.permission_all_read || this.permission_changelog_read;
         }
         else if(permissionType == "changelog_write") {
            return this.permission_all_write || this.permission_changelog_write;
         }
         else if(permissionType == "admin_read") {
            return this.permission_all_read || this.permission_admin_read;
         }
         else if(permissionType == "admin_write") {
            return this.permission_all_write || this.permission_admin_write;
         }
         else if(permissionType == "report_opd_read") {
            return this.permission_all_read || this.permission_report_opd_read;
         }
         else if(permissionType == "report_opd_write") {
            return this.permission_all_write || this.permission_report_opd_write;
         }
         else if(permissionType == "effect_opd_read") {
            return this.permission_all_read || this.permission_effect_opd_read;
         }
         else if(permissionType == "effect_opd_write") {
            return this.permission_all_write || this.permission_effect_opd_write;
         }
         else if(permissionType == "department_read") {
            return this.permission_all_read || this.permission_department_read;
         }
         else if(permissionType == "department_write") {
            return this.permission_all_write || this.permission_department_write;
         }
         else if(permissionType == "client_contact_us_write") {
            return this.permission_all_write || this.permission_contact_us_write;
         }
         else if(permissionType == "client_contact_us_read") {
            return this.permission_all_read || this.permission_contact_us_read;
         }
         else if(permissionType == "room_ward_write") {
            return this.permission_all_write || this.permission_room_ward_write;
         }
         else if(permissionType == "room_ward_read") {
            return this.permission_all_read || this.permission_room_ward_read;
         }
         else if(permissionType == "ipd_read") {
            return this.permission_all_read || this.permission_ipd_read;
         }
         else if(permissionType == "ipd_write") {
            return this.permission_all_write || this.permission_ipd_write;
         }
         else if(permissionType == "settings_web_read") {
            return this.permission_all_read || this.permission_settings_web_read;
         }
         else if(permissionType == "settings_web_write") {
            return this.permission_all_write || this.permission_settings_web_write;
         }

         else {
            return false;
        }
    },
    openDatePicker() {
        this.$refs.picker.togglePicker(true);
    },
     disableDatesAfterMax(date) {
      return date > new Date();
    },
     openImagePicker() {
        if(!this.profile_pic.startsWith('http')){
            this.$refs.image.click();
        }
    },
    handleFileInput(e) {
      const file = e.target.files[0];
      this.profile_pic = URL.createObjectURL(file);
      this.profile_pic_file = file;
    },
    deleteProfilePic() {
      if(this.profile_pic.startsWith('http')){
          this.img_progress = true;

          if(this.$route.params.id){
            this.getDoctor(this.$route.params.id)
         }

         var bodyFormData = new FormData();
         if(this.$route.params.id){
            bodyFormData.append('doctor_id', this.$route.params.id);
         }
          this.deleteProfilePicData(bodyFormData).then((response) => {
            this.img_progress = false;
            if (response.response_code == 200) {
                  this.profile_pic = ''
                  this.profile_pic_file = null;
                  this.$toasted.success(response.message, {duration: 2000,});
            } else{
                  this.$toasted.error(response.message, {duration: 2000,});
            }
          });
      } else {
            this.profile_pic = ''
            this.profile_pic_file = null;
      }
    },
    handleImageError(event) {
      // If there's an error loading the image, set a default image
      event.target.src = '../../assets/dummy-profile.jpg';  // Use your dummy profile image path here
    },
    profilePic() {
      return this.profile_pic || '../../assets/dummy-profile.jpg'; // If profile_pic is empty, fallback to default
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="vue2-daterange-picker/dist/vue2-daterange-picker.css"></style>
